'use client';
import { Button } from '@mantine/core';
import classes from './WelcomeBlock.module.css';
import { Link } from '../../navigation';

export default function WelcomeBlock() {
  return (
    <div className={classes.controls}>
      <Button component={Link} className={classes.control} size="lg" href="/ueber-mich">
        Motivation
      </Button>
      <Button component={Link} className={classes.control} size="lg" href="/politik">
        Politik
      </Button>
      <Button component={Link} className={classes.control} size="lg" href="/pastoraler-raum">
        Pastoraler Raum
      </Button>
    </div>
  );
}
